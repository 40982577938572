import { Icon } from 'antd'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { AppRoutes } from '../AppRoutes'
import polyglot from '../Translator'
import './Footer.scss'
export default function Footer(props) {
  return (
    !props.location.pathname.includes(AppRoutes.FILL_DIAGRAMS) && (
      <footer id="footer">
        <div className="links-container">
          <Col sm={4} smOffset={1}>
            <Row>
              <Col sm={6} smOffset={0} xs={4} xsOffset={2}>
                <Row>
                  <Link to={AppRoutes.ABOUT}>{polyglot.t('About')}</Link>
                </Row>
              </Col>
              <Col sm={6} xs={4}>
                <Row>
                  <Link to={AppRoutes.PRICING}>{polyglot.t('pricing')}</Link>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <Row>
              <Col xs={4}>
                <Row>
                  <Link to={AppRoutes.TERMS}>{polyglot.t('terms')}</Link>
                </Row>
              </Col>
              <Col xs={4}>
                <Row>
                  <Link to={AppRoutes.PRIVACY}>{polyglot.t('privacy')}</Link>
                </Row>
              </Col>
              <Col xs={4}>
                <Row>
                  <Link to={AppRoutes.CONTACT}>{polyglot.t('contactUs')}</Link>
                </Row>
              </Col>
            </Row>
          </Col>
        </div>
        <span>
          {'\u00A9 2019 Redox Technologies '}
          <Icon type="mail" />
          {' support@redox-technologies.ca'}
        </span>
      </footer>
    )
  )
}
